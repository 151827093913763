<template>
  <div class="home" v-loading="loading">
      <div class="row mx-0">
        <div class="col-auto" />
        <div class="col">
          <div class="row mx-0">
              <div class="col-auto text-black ml-5 f-600 f-20">Conjuntos</div>
              <div class="col-3 ml-auto">
                  <el-input placeholder="Buscar" class="w-100" v-model="search" size="small"/>
              </div>
              <div class="col-auto">
                  <router-link :to="{name: 'crear-conjunto'}">
                    <div class="bg-general text-white br-4 px-3 d-middle-center cr-pointer" style="height:32px;">
                        Crear Conjunto
                    </div>
                  </router-link>
              </div>
          </div>
          <div class="row mx-0 mt-3">
              <div class="col-12">
                  <tabla-general 
                  :data="conjuntos.filter(data => !search || data.nombre.toLowerCase().includes(search.toLowerCase()))"
                  class-header="text-general f-16 f-500"
                  :columnas="dataColumns" 
                  alto="calc(100vh - 185px)" 
                  :mostrar-buscador="false" 
                  :usar-paginacion="false" 
                  >
                    <template slot="adicionales-izquierda">
                        <el-table-column
                          width="80">
                            <template slot-scope="scope">
                                <div class="row mx-0">
                                    <img :src="scope.row.logo" class="obj-cover br-4" width="44" height="44" />
                                </div>
                            </template>
                        </el-table-column>                    
                        <el-table-column
                        label="Nombre"
                          width="180">
                            <template slot-scope="scope">
                                <div class="row mx-0">
                                    <router-link 
                                    :to="{name: 'conjuntos.estadisticas', 
                                    params: { id: scope.row.id }}" 
                                    class="text-general" 
                                    style="text-decoration:none;"
                                    >
                                        <div class="col-auto pl-0">
                                            {{scope.row.nombre}}
                                        </div>
                                    </router-link>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                          label="Tipo"
                          width="180">
                            <template slot-scope="scope">
                                <div class="row mx-0">
                                    <div class="col-auto pl-0">
                                      <span v-if="scope.row.tipo==1">Apartamentos</span>
                                      <span v-if="scope.row.tipo==2">Casas</span>
                                      <span v-if="scope.row.tipo==3">Apartamentos y Casas</span>
                                      <span v-if="scope.row.tipo==4">Otros</span>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                    </template>
                    <template slot="adicionales-derecha">
                        <el-table-column
                          width="100">
                            <template slot-scope="scope">
                                <div class="row mx-0">
                                    <div v-if="scope.row.estado == 0" class="bg-pink text-black br-20 px-3 py-1">
                                        Inactivo
                                    </div>

																		<div v-else class="bg-general text-white br-20 px-3 py-1">
                                        Activo
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                    </template>
                  </tabla-general>
              </div>
          </div>
        </div>
        <div class="col-auto" />
      </div>
  </div>
</template>

<script>
import Conjuntos from '~/services/conjuntos'
export default {
  layout: 'default',
  data(){
      return {
          loading:false,
          dataColumns: [
            
            {
               valor: 'viviendas',
               titulo: 'Viviendas',
               class: "text-center"
            },
            {
               valor: 'usuarios',
               titulo: 'Usuarios',
               class: "text-center"
            },
            {
               valor: 'ciudad',
               titulo: 'Ciudad',
               class: "text-center"
            }
          ],
          search: '',
					conjuntos : []
      }
      
  },
	created(){
		this.listar()
	},
  computed: {	
  },
	methods:{
		async listar(){
			try {
        this.loading=true
				const {data} = await Conjuntos.listarConjuntos()
				this.conjuntos = data.data
        this.loading=false

			} catch (e){
					console.log(e)
          this.loading=false

			}
		},
    async filtro(){
			try {
        this.loading=true
        if(this.search == ''){
          this.listar()
          return
        }
        const params = {
          search : this.search
        }
				const {data} = await Conjuntos.filtro(params)
				this.conjuntos = data.data.data
        this.loading=false

			} catch (e){
					console.log(e)
          this.loading=false

			}
		}
	}
  
}
</script>
<style lang="scss" scoped>
a{
    text-decoration: none !important;
}
</style>
